import Cookies from "js-cookie";
import { BASE_URL } from "./config";

export const formatPrices = (price: number) => {
  return `$ ${price?.toFixed(2)}`;
};

export const formatDate = (inputDate: string) => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const prepareHeaders = (headers: Headers) => {
  const token = Cookies.get("token");
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const capitalize = (value: string) => {
  return value.slice(0, 1).toUpperCase() + value.slice(1, value.length);
};

export const isEmptyValue = (value: any) => {
  if (typeof value === "object" && value !== null && "value" in value) {
    return value.value.trim() === "";
  }
  return typeof value === "string" && value.trim() === "";
};

export const allValuesPresent = (object: any) =>
  Object.values(object).every((value) => !isEmptyValue(value));

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const validateEmail = (email: string) => {
  return emailRegex.test(email);
};

export const fetchBaseQueryHelper = {
  baseUrl: BASE_URL,
  prepareHeaders: prepareHeaders,
};

export const isFileSizeValid = (file: File): boolean => {
  const maxSizeInBytes = 5 * 1024 * 1024;
  return file.size <= maxSizeInBytes;
};

export const isFileAnImage = (file: File): boolean => {
  const validImageTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/jpg",
  ];

  const isImage = validImageTypes.includes(file.type);

  if (!isImage) {
    return false;
  }
  return true;
};

export const isFileFormatValid = (file: File): boolean => {
  const validImageTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/jpg",
    "image/heic",
    "image/heif",
  ];

  // Check file extension for HEIC/HEIF files
  const fileName = file.name.toLowerCase();
  const isHeicHeif = fileName.endsWith(".heic") || fileName.endsWith(".heif");

  const isPDF = file.type === "application/pdf";
  const isImage = validImageTypes.includes(file.type) || isHeicHeif;

  return isPDF || isImage;
};

export const isEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true;
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return obj1 === obj2;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;
  for (const key of keys1) {
    if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) return false;
  }
  return true;
};

export const areBothDatesPresent = (
  fromDate: string | null,
  toDate: string | null
) => {
  return (
    fromDate !== null && toDate !== null && fromDate !== "" && toDate !== ""
  );
};

export const isOneDatePresent = (
  fromDate: string | null,
  toDate: string | null
) => {
  return (
    (fromDate !== "" && toDate === "") || (fromDate === "" && toDate !== "")
  );
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  const digitsOnly = phoneNumber.replace(/\D/g, "");
  return digitsOnly.length >= 11 && digitsOnly.length <= 13;
};

export const isPromoCodeDescriptionValid = (
  description: string,
  discountType: string,
  discountValue: string,
  serviceTypes: string[],
  serviceTypeMap: Record<string, string>
): { isValid: boolean; error: string } => {
  const descriptionLower = description.toLowerCase();

  // Check if description is empty
  if (!description.trim()) {
    return { isValid: false, error: "Description is required" };
  }

  // Validate discount type and value
  if (discountType === "flat") {
    if (
      !descriptionLower.includes("$" + discountValue) &&
      !descriptionLower.includes("$ " + discountValue)
    ) {
      return {
        isValid: false,
        error: `Description must include the flat discount amount: $${discountValue}`,
      };
    }
    // Ensure no percentage symbols
    if (descriptionLower.includes("%")) {
      return {
        isValid: false,
        error:
          "Description cannot contain percentage symbols for flat discount",
      };
    }
  } else if (discountType === "percentage") {
    if (
      !descriptionLower.includes(discountValue + "%") &&
      !descriptionLower.includes(discountValue + " %")
    ) {
      return {
        isValid: false,
        error: `Description must include the percentage discount: ${discountValue}%`,
      };
    }
    // Ensure no dollar symbols
    if (descriptionLower.includes("$")) {
      return {
        isValid: false,
        error:
          "Description cannot contain dollar symbols for percentage discount",
      };
    }
  }

  // Get all possible service types excluding 'all'
  const allServiceTypes = Object.entries(serviceTypeMap)
    .filter(([key]) => key !== "all")
    .map(([key, value]) => ({
      key,
      searchTerm: value.toLowerCase(),
    }));

  // If "all" is selected, all service types should be mentioned
  if (serviceTypes.includes("all")) {
    const missingServices = allServiceTypes
      .filter((service) => !descriptionLower.includes(service.searchTerm))
      .map((service) => service.searchTerm);

    if (missingServices.length > 0) {
      return {
        isValid: false,
        error: `Description must include all service types: in store pickup, curbside pickup and package pickup`,
      };
    }
  } else {
    // Create sets for selected and mentioned services
    const selectedServiceSet = new Set(serviceTypes);
    const mentionedServices = allServiceTypes.filter((service) =>
      descriptionLower.includes(service.searchTerm)
    );

    // Check if all selected services are mentioned
    const missingSelectedServices = serviceTypes.filter(
      (type) => !mentionedServices.some((service) => service.key === type)
    );

    if (missingSelectedServices.length > 0) {
      const missingServiceNames = missingSelectedServices
        .map((type) => serviceTypeMap[type].toLowerCase() + " pickup")
        .join(", ");
      return {
        isValid: false,
        error: `Description must include selected service types: ${missingServiceNames}`,
      };
    }

    // Check if any non-selected services are mentioned
    const unwantedServices = mentionedServices.filter(
      (service) => !selectedServiceSet.has(service.key)
    );

    if (unwantedServices.length > 0) {
      const unwantedServiceNames = unwantedServices
        .map((service) => service.searchTerm)
        .join(", ");
      return {
        isValid: false,
        error: `Description cannot include non-selected service types: ${unwantedServiceNames}`,
      };
    }
  }

  return { isValid: true, error: "" };
};

type Environment = "production" | "staging" | "development" | "";

export function getEnvironmentFromUrl(url: string): Environment {
  // Convert to lowercase for case-insensitive matching
  const lowercaseUrl = url.toLowerCase();

  // Create a URL object to properly parse the hostname
  try {
    const urlObject = new URL(lowercaseUrl);
    const hostname = urlObject.hostname;

    // Check for environment indicators in the hostname
    if (hostname.startsWith("api.dev.")) {
      return "development";
    } else if (hostname.startsWith("api.staging.")) {
      return "staging";
    } else if (hostname.startsWith("api.")) {
      return "production";
    }

    console.log("Invalid API URL format");
    return "";
  } catch (error) {
    console.log("Invalid URL provided");
    return "";
  }
}
