// @ts-nocheck

import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { getToken } from "../firebase";
import { BASE_URL } from "./config";
import {
  deleteSessionCookie,
  getSessionCookie,
  setSessionCookie,
} from "./cookies";
import { ActiveUsersApiResponse } from "../pages/Analytics";

export const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use(
  async (config) => {
    const token = getSessionCookie("token");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (err) => Promise.reject(err)
);

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error?.response?.status === 401) {
      deleteSessionCookie("token");
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);
export default class ApiManager {
  async get({ url, params, headers }) {
    try {
      const res = await instance.get(url, { params, headers });
      return res.data;
    } catch (error) {
      return error;
    }
  }

  async Login(email, password) {
    let response;
    try {
      response = await instance.post("/auth/admin/login", { email, password });
      setSessionCookie("token", response.data.token);
      return { success: true, payload: response.data };
    } catch (err) {
      console.log(err);
      return { success: false, error: err };
    }
  }
  async WhoAmI() {
    let user;
    try {
      user = await instance.get("/auth/admin/whoami");
      return { success: true, payload: user };
    } catch (error) {
      return { success: false, error: error };
    }
  }
  async getUsers(
    limit = 10,
    page = 1,
    sort = "desc",
    search = "",
    status = 1,
    searchKey = "fullName",
    createdago
  ) {
    let users;
    let blocked = 0;
    let approved = 1;
    if (status === "not approved") {
      approved = 0;
      blocked = 0;
    }
    if (status === "blocked") {
      blocked = 1;
      approved = 1;
    }
    try {
      let users = await instance.get("/admin/user/all", {
        params: {
          limit,
          page,
          sort,
          search,
          blocked,
          approved,
          searchKey,
          past: createdago,
        },
      });
      return { success: true, payload: users };
    } catch (error) {
      if (error?.response?.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return { success: false, error: error };
    }
  }
  async getDrivers(
    limit = 10,
    page = 1,
    sort = "desc",
    search,
    searchKey,
    status,
    zipcode,
    online,
    createdago
  ) {
    let drivers;
    let blocked = 0;
    let approved = 1;
    let allowed = 1;
    let page_;
    if (status === "" && search === "") {
      approved = 1;
      blocked = 0;
      allowed = 1;
    } else if (status === "" && search !== "") {
      approved = null;
      blocked = null;
      allowed = null;
    }
    if (search === "" && page > 1) {
      page_ = page;
    } else if (search !== "" && page > 1) {
      page_ = null;
    }
    if (status === "not approved") {
      approved = 0;
      blocked = null;
      allowed = null;
    }
    if (status === "not allowed") {
      allowed = 0;
      approved = null;
      blocked = null;
    }
    if (status === "blocked") {
      blocked = 1;
      approved = null;
      allowed = null;
    }

    try {
      let drivers = await instance.get("/admin/driver/all", {
        params: {
          limit,
          page: page_,
          sort,
          search,
          searchKey: "email",
          blocked,
          past: createdago,
        },
      });
      return { success: true, payload: drivers };
    } catch (error) {
      if (error.response.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return { success: false, error };
    }
  }
  async getBrands(
    limit = 10,
    sort = "desc",
    search,
    searchKey = "brandName",
    blocked = 0,
    zipcode,
    page = 1,
    createdago,
    fromDate,
    toDate
  ) {
    let res;
    try {
      let res = await instance.get("/admin/brand/all", {
        params: {
          limit,
          sort,
          search,
          searchKey,
          blocked,
          zipcode,
          page,
          createdago,
          fromdate: fromDate,
          todate: toDate,
        },
      });
      return { success: true, payload: res };
    } catch (error) {
      if (error.response.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return { success: false, error };
    }
  }
  async getOrders(
    limit = 10,
    sort = "desc",
    page = 1,
    fromdate,
    todate,
    users,
    brands,
    drivers,
    createdago,
    status
  ) {
    let res;
    try {
      let res = await instance.get("/admin/order/all", {
        params: {
          limit,
          sort,
          page,
          fromdate,
          todate,
          users,
          brands,
          drivers,
          createdago,
          status,
        },
      });
      return { success: true, payload: res };
    } catch (error) {
      // if (error.response.status === 401) {
      // 	deleteSessionCookie("token");
      // 	window.location.reload();
      // }
      return { success: false, error };
    }
  }

  async getDashboardStats() {
    let res;
    try {
      let res = await instance.get("/admin/dashboard-stats");
      return { success: true, payload: res };
    } catch (error) {
      if (error?.response?.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return { success: false, error };
    }
  }
  async getExtendedStats() {
    let res;
    try {
      res = await instance.get("/admin/dashboard-stats-extended");
      return { success: true, payload: res };
    } catch (err) {
      if (err?.response?.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return { success: false, err };
    }
  }
  async filterExtendedStats(past) {
    let res;
    try {
      if (past === "all") {
        res = await instance.get("/admin/dashboard-stats-extended");
        return { success: true, payload: res };
      } else {
        res = await instance.get(
          `/admin/dashboard-stats-extended?past=${past}`
        );
        return { success: true, payload: res };
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return { success: false, err };
    }
  }
  async getUserDetails(id) {
    let res;
    try {
      res = await instance.get(`/admin/user/${id}`);
      return { success: true, payload: res };
    } catch (error) {
      if (error?.response?.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return { success: false, error };
    }
  }
  async getDriverDetails(id) {
    let res;
    try {
      res = await instance.get(`/admin/driver/${id}`);
      return { success: true, payload: res };
    } catch (err) {
      if (err.response.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return { success: false, err };
    }
  }
  async getBrandDetails(id) {
    let res;
    try {
      res = await instance.get(`/admin/brand/${id}`);
      return { success: true, payload: res };
    } catch (err) {
      if (err.response.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return { success: false, err };
    }
  }
  async getOrderDetails(id) {
    let res;
    try {
      res = await instance.get(`/admin/order/${id}`);
      return { success: true, payload: res };
    } catch (err) {
      if (err.response.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return { success: false, err };
    }
  }
  async getUserOrders(id, search = "", page, limit = 4, sort = "desc") {
    let response;
    try {
      response = await instance.get("/admin/order/all", {
        params: { q: search, page: page, limit, sort, users: id },
      });
      return { success: true, payload: response.data };
    } catch (error) {
      if (error.response.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return error.response.data;
    }
  }
  async getDriverOrders(id, search = "", page, limit = 4, sort = "desc") {
    let response;
    try {
      response = await instance.get("/admin/order/all", {
        params: { q: search, page: page, limit, sort, drivers: id },
      });
      return { success: true, payload: response.data };
    } catch (error) {
      if (error.response.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return error.response.data;
    }
  }

  async getBrandOrders(id, search = "", page, limit = 4, sort = "desc") {
    let response;
    try {
      response = await instance.get("/admin/order/all", {
        params: { q: search, page: page, limit, sort, brands: id },
      });
      return { success: true, payload: response.data };
    } catch (error) {
      if (error.response.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return error.response.data;
    }
  }

  async createBrand(
    brandName,
    email,
    password,
    contact,
    zipCode,
    brandLogo,
    location,
    coordinates
  ) {
    let res;
    try {
      res = await instance.post("/admin/brand/create", {
        brandName,
        email,
        password,
        contact,
        zipCode,
        brandLogo,
        location,
        coordinates,
      });
      return { success: true, payload: res };
    } catch (err) {
      return err.response.data;
    }
  }
  async createUser(
    fullName,
    email,
    gender,
    password,
    repeat_password,
    phone,
    profileImage,
    state
  ) {
    const fd = new FormData();
    let res;
    let res2;
    try {
      fd.append("file", profileImage);
      fd.append("type", "profilePhoto");
      fd.append("userType", "user");
      fd.append("email", email);
      res2 = await instance.post("/upload", fd);
      const file = res2?.data?.Location;

      res = await instance.post("/admin/user/create", {
        fullName,
        email,
        password,
        repeat_password,
        phone,
        gender,
        profileImage: file,
        state,
      });
      return { success: true, payload: res };
    } catch (err) {
      if (err.response.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return { err: err.response.data };
    }
  }
  async createDriver({
    fullName,
    email,
    password,
    phone,
    vehicleNumber,
    profileImage,
    drivingLicenseFile,
    zipCode,
    vehicleType,
    vehicleMake,
    vehicleModel,
    vehicleMakeYear,
    driverLicenseNumber,
    driverLicenseState,
    dob,
    state,
    vehicleInsuranceFile,
  }) {
    const fd = new FormData();
    const fd2 = new FormData();
    const fd3 = new FormData();
    const fd4 = new FormData();
    let res;
    let res2;
    try {
      fd.append("file", profileImage);
      fd.append("type", "profilePhoto");
      fd.append("userType", "driver");
      fd.append("email", email);
      res2 = await instance.post("/upload", fd);
      const profileImageUrl = res2?.data?.Location;
      fd3.append("file", drivingLicenseFile);
      fd3.append("type", "drivingLicense");
      fd3.append("userType", "driver");
      fd3.append("email", email);
      const res4 = await instance.post("/upload", fd3);
      const drivingLicenseUrl = res4?.data.Location;
      fd2.append("file", vehicleInsuranceFile);
      fd2.append("type", "vehicleInsurance");
      fd2.append("userType", "driver");
      fd2.append("email", email);
      const res3 = await instance.post("/upload", fd2);
      const vehicleInsuranceFileUrl = res3?.data?.Location;
      res = await instance.post("/admin/driver/create", {
        fullName,
        email,
        password,
        phone,
        vehicleNumber,
        profileImage: profileImageUrl,
        drivingLicenseFile: drivingLicenseUrl,
        zipCode,
        vehicleType,
        vehicleMake,
        vehicleModel,
        vehicleMakeYear,
        dob,
        driverLicenseNumber,
        driverLicenseState,
        state,
        vehicleInsuranceFile: vehicleInsuranceFileUrl,
      });
      return { success: true, payload: res };
    } catch (err) {
      if (err.response.status === 401) {
        deleteSessionCookie("token");
        window.location.reload();
      }
      return { err: err.response.data };
    }
  }
  async changePassword(oldPassword, password) {
    let res;
    try {
      res = await instance.post("/auth/admin/change-password", {
        oldPassword,
        password,
      });
      return { success: true, payload: res };
    } catch (err) {
      return { success: false, error: err.response.data.error };
    }
  }
  async getNotifications(token) {
    let res;
    try {
      res = await instance.patch("/admin/fcm", { fcm: token });
      // console.log(res)
    } catch (err) {
      return err;
    }
  }
  async blockUsers(id, blocked) {
    let res;
    try {
      res = await instance.patch(`/admin/user/${id}/modify`, {
        isBlocked: blocked,
      });
      return { success: true, payload: res };
    } catch (err) {
      return err;
    }
  }
  async blockDriver(id, blocked) {
    let res;
    try {
      res = await instance.patch(`/admin/driver/${id}/modify`, {
        isBlocked: blocked,
      });
      return { success: true, payload: res };
    } catch (err) {
      return err;
    }
  }

  async isallowdto_serve(id) {
    let res;
    try {
      res = await instance.patch(`/admin/driver/${id}/modify`, {
        isAllowedToServe: "true",
      });
      return { success: true, payload: res };
    } catch (err) {
      return err;
    }
  }

  async blockBrand(id, blocked) {
    let res;
    try {
      res = await instance.patch(`/admin/brand/${id}/modify`, {
        isBlocked: blocked,
      });
      return { success: true, payload: res };
    } catch (err) {
      return err;
    }
  }

  async logout() {
    let res;
    try {
      const token = await getToken();
      if (!token) {
        Cookies.remove("token");
        return { success: true };
      }
      res = await instance.patch("/admin/logout", { fcm: token });
      Cookies.remove("token");
      return { success: true };
    } catch (err) {
      return err;
    }
  }
  async changeWithdrawlStatus(status, id) {
    try {
      let res = await instance.put("/transactions/", {
        transaction: id,
        withdrawalStatus: status,
      });
      return { success: true, payload: res };
    } catch (error) {
      console.log(error);
    }
  }
  async testOrders(storeOrderId, items, userMeta, brandAddress) {
    let res;
    try {
      res = await instance.post(
        "/brand/order",
        { storeOrderId, items, userMeta, brandAddress: [brandAddress] },
        {
          headers: {
            id: "6203eb9c1df962799c0c9a91",
            key: "7fd350752989b108474f192d6b1ee4fbd2da1bdd8798073be0f943dd85f0cc8ded73567e30232c60",
          },
        }
      );
      console.log(res);
      return { success: true, payload: res };
    } catch (err) {
      return { success: false, payload: err.response };
      console.log(err.response);
    }
  }
  async getAllServices({
    type,
    page,
    createdago,
    fromdate,
    todate,
    users,
    drivers,
    status,
    limit = 10,
  }: {
    type?: string;
    page?: number;
    createdago?: string;
    fromdate?: string;
    todate?: string;
    users?: string;
    drivers?: string;
    limit: number;
    status: string;
  }) {
    let res;
    try {
      if (fromdate && todate) {
        if (fromdate <= todate) {
          res = await instance.get("/admin/services/all", {
            params: {
              type,
              page,
              createdago,
              fromDate: fromdate,
              toDate: todate,
              user: users,
              driver: drivers,
              limit,
              status,
            },
          });
          return { success: true, payload: res };
        } else {
          return {
            success: false,
            message:
              "Invalid Date Range: The 'from' date cannot be later than the 'to' date",
          };
        }
      } else {
        res = await instance.get("/admin/services/all", {
          params: {
            type,
            page,
            createdago,
            fromDate: fromdate,
            toDate: todate,
            user: users,
            driver: drivers,
            limit,
            status,
          },
        });
        return { success: true, payload: res };
      }
    } catch (err) {
      console.log(res);
    }
  }
  async getServiceById(id) {
    let res;
    try {
      res = await instance.get(`/admin/service/${id}`);
      return { success: true, payload: res };
    } catch (err) {
      console.log(err);
    }
  }

  async gettransactions(type, page) {
    let res;
    try {
      res = await instance.get("transactions", { params: { type, page } });
      return { success: true, payload: res };
    } catch (err) {
      console.log(err);
    }
  }

  async getwithdraw(withdrawalStatus?: string, page?: number) {
    let res;
    try {
      res = await instance.get("transactions/?type=withdrawal", {
        params: { withdrawalStatus, page },
      });
      return { success: true, payload: res };
    } catch (err) {
      console.log(err);
    }
  }

  async payout(body) {
    try {
      const res = await instance.post("/stripe/transfers", body);
      return { success: true, payload: res };
    } catch (error) {
      return { success: false, error: error?.response?.data?.error };
    }
  }

  async cancelService(serviceId) {
    let res;
    try {
      res = await instance.patch(`/admin/services/${serviceId}/cancel`);
      return { status: res?.status, data: res.data };
    } catch (error) {
      return { status: res?.status, error };
    }
  }

  async appVersion(body: {
    latestVersion: string;
    isUpdateRequired: boolean;
    userType: string;
  }) {
    try {
      const res = await instance.post("/app/version", body);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }
  async getAppVersions() {
    try {
      const res = await instance.get("/app/version");
      return { status: res.status, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async createAdmin(body) {
    try {
      const res = await instance.post("/auth/admin/register", body);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }
  async getAllStates() {
    try {
      const res = await instance.get("/states/getStates");
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getVehicleDetails(params?: { type?: string; make?: string }) {
    try {
      const res = await instance.get("/vehicles/vehiclesDetails", {
        params: { ...params },
      });
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async addState(body: { name: string; symbol: string }) {
    try {
      const res = await instance.post("/states/addState", {
        state: { ...body },
      });
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getLaunchedStates(params?: {
    limit?: number;
    page?: number;
    search?: string;
  }) {
    try {
      const res = await instance.get("/admin/states/all", {
        params: { ...params },
      });
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async sendOtp(email: string) {
    try {
      const res = await instance.get(`/auth/admin/forget-password/${email}`);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async verifyOtp(body: { code: number; email: string }) {
    try {
      const res = await instance.post("/auth/admin/verify", body);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async resetPassword(body: { password: string; token: string }) {
    try {
      const res = await instance.post(
        "/auth/admin/reset-password",
        { password: body.password },
        {
          headers: {
            Authorization: `Bearer ${body.token}`,
          },
        }
      );
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async deleteState(id: string) {
    try {
      const res = await instance.delete(`/admin/states/${id}/delete`);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getAllPromoCodes(params) {
    try {
      const res = await instance.get("/admin/promoCode/all", {
        params: { ...params },
      });
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async addPromo(body: {
    description: string;
    discountType: string;
    discountValue: number;
    expiryDate: null | string;
    maxUsers: number;
    promoCode: string;
    serviceType: string[];
    startDate: string;
    maxBudget?: number;
    isNotifyUser: boolean;
    isSingleUse: boolean;
  }) {
    try {
      const res = await instance.post("/promoCode/addPromoCode", body);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  async editPromo({
    id,
    ...rest
  }: {
    description: string;
    discountType: string;
    discountValue: number;
    expiryDate: null | string;
    maxUsers: number;
    promoCode: string;
    serviceType: string[];
    startDate: string;
    isNotifyUser: boolean;
    maxBudget?: number;
    id: string;
    isSingleUse: boolean;
  }) {
    try {
      const res = await instance.patch(`/admin/promoCode/${id}/modify`, {
        ...rest,
      });
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async deletePromoCode(id: string) {
    try {
      const res = await instance.delete(`/admin/promoCode/${id}/delete`);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async modifyPromoCodeActiveStatus(id: string, isPromoCodeActive: boolean) {
    try {
      const res = await instance.patch(
        `/admin/promoCode/${id}/togglePromoCode`,
        {
          isPromoCodeActive,
        }
      );
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getAllActiveOffers() {
    try {
      const res = await instance.get("/offer/active-offers");
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getAllOffers(params?: { page: number; serviceType: string }) {
    try {
      const res = await instance.get("/admin/offer/all", {
        params: { ...params },
      });
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async addOffer(body: {
    pointsRequired: number | string;
    discountValue: number | string;
    discountType: string;
    serviceType: string[];
    validFrom: string;
    validTo: string;
    isOfferActive: boolean;
  }) {
    try {
      const res = await instance.post("/admin/add-offer", body);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async editOffer(
    id: string,
    body: {
      pointsRequired: number | string;
      discountValue: number | string;
      discountType: string;
      serviceType: string[];
      validFrom: string;
      validTo?: string;
      isOfferActive: boolean;
    }
  ) {
    try {
      const res = await instance.patch(`/admin/offer/${id}/modify`, body);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async toggleOfferStatus({
    id,
    isOfferActive,
  }: {
    id: string;
    isOfferActive: boolean;
  }) {
    try {
      const res = await instance.patch(`/admin/offer/${id}/toggleOffer`, {
        isOfferActive,
      });
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async deleteOffer(id: string) {
    try {
      const res = await instance.delete(`/admin/offer/${id}/delete`);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getAdmins(params) {
    try {
      const res = await instance.get("/admin/admins/all", {
        params: { ...params },
      });
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async addAdmin(body) {
    try {
      const res = await instance.post("/auth/admin/register", body);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async deleteAdmin(id: string) {
    try {
      const res = await instance.delete(`/admin/admin/${id}/delete`);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getDriverDetails(id: string) {
    try {
      const res = await instance.get(`/admin/driver/${id}`);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async modifyDriver(id: string, body: any) {
    try {
      const res = await instance.patch(`/admin/driver/${id}/modify`, body);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getResubmittedDocuments(id: string) {
    try {
      const res = await instance.get(`/admin/resubmit/${id}`);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async approveOrRejectResubmittedDocuments(
    id: string,
    isApproved: boolean,
    reason?: string
  ) {
    try {
      let body = {
        isApproved,
      };
      if (!isApproved && reason) {
        body.rejectReason = reason;
      }
      const res = await instance.patch(`/admin/resubmit/${id}/modify`, body);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getAllComplaints(params) {
    try {
      const res = await instance.get("/admin/complaint/all", {
        params: { ...params },
      });
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getComplaintById(id: string) {
    try {
      const res = await instance.get(`/admin/complaint/${id}`);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getAllNotifications(params) {
    try {
      const res = await instance.get("/notifications/admin/all", {
        params: { ...params },
      });
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async deleteNotification(id: string) {
    try {
      const res = await instance.delete(`/notifications/admin/${id}`);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getAllSubmissionRequests(params) {
    try {
      const res = await instance.get("/admin/request/all", {
        params: { ...params },
      });
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async modifySubmissionRequest(
    id: string,
    body: { isAllowedToResubmit: boolean }
  ) {
    try {
      const res = await instance.patch(`/admin/request/${id}/modify`, body);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  async getComplaintReplies(
    complaintId: string,
    params?: { sort?: string; page?: number }
  ) {
    try {
      const res = await instance.get(`/helpdesk/get-messages/${complaintId}`, {
        params: { ...params },
      });
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async sendComplaintReply(body: {
    message: string;
    to: string;
    toUserType: string;
    complaint: string;
  }) {
    try {
      const res = await instance.post(`/helpdesk/send-message`, {
        ...body,
        userType: "admin",
      });
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getActiveUsersData({
    startDate = "7daysAgo",
    endDate = "today",
  }: {
    startDate?: string;
    endDate?: string;
  }): Promise<{
    success: boolean;
    data?: ActiveUsersApiResponse;
    error?: unknown;
  }> {
    try {
      const body = {
        dimensions: ["date"],
        metrics: ["activeUsers"],
        dateRanges: [
          {
            startDate: startDate,
            endDate: endDate,
          },
        ],
      };
      const res = await instance.post("/analytics/get-runReport-events", body);
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getFirstOpenData({
    startDate = "7daysAgo",
    endDate = "today",
  }: {
    startDate?: string;
    endDate?: string;
  }) {
    try {
      const body = {
        dateRanges: [
          {
            startDate: startDate,
            endDate: endDate,
          },
        ],
        metrics: ["eventCount"],
        dimensions: ["eventName", "date"],
        filters: {
          filter: {
            fieldName: "eventName",
            stringFilter: { value: "first_open" },
          },
        },
      };
      const res = await instance.post("/analytics/get-runReport-events", body);
      const sortedRes = res.data?.filter(
        (item) => item?.dimensions[0] === "first_open"
      );
      const arr = sortedRes?.map((item) => ({
        dimensions: `${item?.dimensions[1].slice(
          0,
          4
        )}-${item?.dimensions[1].slice(4, 6)}-${item?.dimensions[1].slice(6)}`,
        metrics: parseInt(item?.metrics[0]),
      }));
      const sortedArr = arr?.sort((a, b) =>
        a.dimensions.localeCompare(b.dimensions)
      );
      return { success: true, data: sortedArr };
    } catch (error) {
      return { success: false, error };
    }
  }
  async verifyDriverBackground(id: string) {
    try {
      const res = await instance.post(`/admin/driver/${id}/background-check`);
      return { success: true, data: res.data };
    } catch (error) {
      return {
        success: false,
        error: axios.isAxiosError(error)
          ? error?.response?.data?.error
          : "Something went wrong",
      };
    }
  }
  async getDriverAddress(driverId: string){
    try {
      const res = await instance.get(`/admin/driver-address/${driverId}`)
      return {success: true, data: res.data}
    } catch (error) {
      return {
        success: false,
        error: axios.isAxiosError(error)
          ? error?.response?.data?.error
          : "Something went wrong",
      }
    }
  }
  async getBusinessAccounts(params?: {page?: number | string; search?: string; fromDate?: string; todate?: string; state?: string}){
    try {
      const res = await instance.get("/admin/business-accounts/all", {
        params: { ...params },
      })
      return {success: true, data: res.data}
    } catch (error) {
      return {
        success: false,
        error: axios.isAxiosError(error)
          ? error?.response?.data?.error
          : "Something went wrong",
      }
    }
  }
  async getBusinessAccountDetails(id: string){
    try {
      const res = await instance.get(`/admin/business-account/${id}`)
      return {success: true, data: res.data}
    } catch (error) {
      return {
        success: false,
        error: axios.isAxiosError(error)
          ? error?.response?.data?.error
          : "Something went wrong",
      }
    }
  }
}
